<template>
    <div>
        <v-toolbar dense color="primary" dark>
            <v-toolbar-title>Documentos y archivos</v-toolbar-title>
        </v-toolbar>
        <v-data-table
            elevation="2"
            :headers="headers"
            :items="datos"
            disable-sort
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            item-key="id"
            :loading="loadingDatosTodos"

            :server-items-length='totalRegistros'
            :options.sync="optionsTable"
        >

            <template v-slot:[`item.creado`]="{ item }">
                <div v-html="item.creado"></div>
            </template>

            <template v-slot:[`item.view`]="{ item }">
                <v-icon @click="getArchivo(item.archivoDocumento)">mdi-folder</v-icon>
            </template>

            <template v-slot:[`item.observaciones`]="{ item }">
                <div v-html="item.observaciones"></div>
            </template>

            <!--
                <template v-slot:[`item.view`]="{ item }">
                    <v-icon @click="cargaDatos(item)">mdi-magnify</v-icon>
                </template>
                <template v-slot:[`item.areaNom`]="{ item }">
                    <div>{{item.areaNom}}</div>
                    <div>({{item.servicioNom}})</div>
                </template>

                <template v-slot:[`item.veInter`]="{ item }">
                    <v-icon @click="$router.push({path:`/hospital/interna/`+item.id})">mdi-folder</v-icon>
                </template>
            -->

        </v-data-table>

    </div>
</template>

<script>
export default {
    created(){
        //this.carga()
    },
    props:{
        dp:Object,
        tab: Number,
        esteTab:Number,
    },
    data:()=>({
        params:{},
        headers:[
            {text:'Documento', value:'tipoDocumento'},
            {text:'Creado por', value:'creado'},
            {text:'Vigencia', value:'vigenciaDocumento'},
            {text:'Observaciones', value:'observaciones'},
            {text:'', value:'view', width:'5%'},
        ],

        datos:[],
        loadingDatosTodos:false,
        optionsTable:{},
        totalRegistros:0,
    }),

    methods:{
        async carga(){

            //this.params={...this.optionsTable}
            this.params.id = this.dp.pacienteId
            this.params.start=(this.optionsTable.page-1 )*this.optionsTable.itemsPerPage
            this.params.limit=this.optionsTable.itemsPerPage
            this.loadingDatosTodos=true
            try {
                let req = await this.$http({
                    url: '/pacientes/listarArchivos',
                    method:'GET',
                    params: this.params
                })
                this.loadingDatosTodos=false
                this.datos = req.data.root
                this.totalRegistros = req.data.total
            } catch (err) {
                this.loadingDatosTodos=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        getArchivo(archivo){
            //this.dialogImprime = false
            //let url = this.$baseUrl+'hospital/imprimenota/?id='+this.estaNota+'&se='+this.altura+'&en='+this.encabezado
            console.log(archivo)
            window.open(archivo,'winname','directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=600')
            //http://localhost:9999/secah2015/hospital/imprimeindicaciones?id=177736&se=9&en=true

        },
    },

    watch:{
        tab(){
            if(this.tab == this.esteTab){
                this.carga()
            }
        },
        optionsTable: {
            handler () {
                //console.log(this.optionsTable)
                this.carga()
            },
            deep: true,
        },
    },
}
</script>

<style>

</style>